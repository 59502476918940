<template>
  <v-container grid-list-md>
    <v-card style="margin-bottom:5em">
      <v-card-title>Off-Campus Studies Application</v-card-title>
      <v-card-text>
        <v-form v-model="formValid" lazy-validation ref="form">
          <v-row wrap>
            <v-col cols="12" md="6">
              <v-text-field :value="name" readonly label="Name"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field :value="bannerId" readonly label="Banner ID"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete v-model="majors" :items="majorOptions" label="Major(s)" outlined multiple small-chips :rules="[ v => v.length > 0 || 'Major is required' ]"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-select v-model="gradYear" :items="gradYearOptions" :rules="[ v => !!v > 0 || 'Grad Year is required' ]" label="Graduation Year" outlined></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-combobox v-model="program" :items="programOptions" label="Approved Off-Campus Study Program" item-text="search" item-value="search" outlined @change="checkInternational" :rules="[ v => !!v || 'Program is required' ]">
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.city }}</v-list-item-title>
                    <v-list-item-subtitle>Partner: {{ item.partner }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" md="6">
              <v-select v-model="programTerm" :items="terms" :rules="[ v => !!v || 'Term is required' ]" label="Term of Program" outlined></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-checkbox v-model="requiredForMajor" label="Program is required for my major" outlined></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
              <v-checkbox v-model="international" :disabled="typeof (program) === 'object'" label="Program is international" outlined></v-checkbox>
            </v-col>
            <v-col cols="12" v-if="international">
              <p>International programs require you to have a passport. You will need to scan a copy of your passport and upload it after you submit this form. This will be required before your application can be approved.</p>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="email" :rules="emailRules" label="Email while studying abroad" outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="instagram" label="Instagram ID (optional)" outlined></v-text-field>
            </v-col>
          </v-row>
          <p>
            <b>Student Commitment:</b> "I understand and agree to adhere to all policies regarding off-campus study as described in the Student Handbook, Off-Campus Studies Handbook and in the Catalog under Financial Planning and Academic Information. I understand that I must be covered by a medical/emergency evacuation insurance policy during my off-campus study program. I agree to notify Covenant College if there are any changes in this application."
          </p>
          <v-text-field v-model="insuranceCompany" :rules="[ v => !!v || 'Medical Insurance is required' ]" label="Medical Insurance Company" outlined></v-text-field>
          <v-divider style="margin:2em"></v-divider>
          <h3>Emergency Contacts</h3>
          <v-row v-for="(contact, index) in emergencyContact" :key="'emcont-' + index">
            <v-col cols="12" v-if="index > 0" class="pt-0 pb-0">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="contact.name" :rules="[ v => !!v || 'Name is required' ]" label="Name" outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="contact.relation" :rules="[ v => !!v || 'Relation is required' ]" label="Relation" outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="contact.email" :rules="emailRules" label="Email Address" outlined></v-text-field>
            </v-col>
            <v-col :sm="index > 0 ? 10 : 12" :md="index > 0 ? 5 : 6">
              <v-text-field v-model="contact.phone" :rules="[ v => !!v || 'Phone is required' ]" label="Phone Number" outlined></v-text-field>
            </v-col>
            <v-col v-if="index > 0" sm="2" md="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" color="error" @click="emergencyContact.splice(index, 1)" style="margin-top:1em">
                    <v-icon>fal fa-times</v-icon>
                  </v-btn>
                </template>
                <span>Remove Emergency Contact</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-btn text color="primary" @click="emergencyContact.push({ name:'', relation:'', phone:'', email:'' })">Add Another Emergency Contact</v-btn>
          <v-divider style="margin:2em"></v-divider>
          <b>Expenses Payable to Covenant College</b> before accounting for any applicable Financial Aid; please see Financial Aid for detailed FA package information for your semester abroad.
          <table style="margin-left:2em">
            <tr>
              <td style="font-weight:bold">Tuition (Full-time student rate)*</td>
              <td style="text-align:right;padding-left:1em">$17,835</td>
            </tr>
            <tr>
              <td style="font-weight:bold">Off-Campus Study Fee</td>
              <td style="text-align:right">$4,000</td>
            </tr>
            <tr style="font-weight: bold">
              <td style="font-weight:bold">Total due to Covenant College*</td>
              <td style="text-align:right">$21,330</td>
            </tr>
          </table>
          <div>*This is the 2019/2020 rate</div>
          <p>Students may be responsible for additional expenses depending on program details (application fees, deposits, travel, books, passport, visa, etc.)</p>
          <v-row>
            <v-col :cols="12" md="8" lg="6" xl="4">
              <v-select v-model="pell" :items="[{ text:'Yes', value:true },{ text:'No', value:false }]" label="Do you receive the Pell Grant?" outlined></v-select>
            </v-col>
          </v-row>
          <v-divider style="margin:2em"></v-divider>
          <p>Please select your academic advisor from the list. If your advisor is not on this list, please contact the Records office.</p>
          <v-row>
            <v-col cols="12" md="8" lg="6" xl="4">
              <v-autocomplete v-model="advisor" :items="advisorOptions" label="Academic Advisor" item-text="name" item-value="username" return-object outlined></v-autocomplete>
            </v-col>
          </v-row>
          <p>Please list the course(s) below that you will be taking, the number of credits, and their Covenant course equivalent. These must be approved by the head of the academic department or registrar's office.</p>
          <v-row v-for="(course, index) in courses" :key="'course-'+index" class="no-bottom-padding">
            <v-col cols="12" md="5">
              <v-text-field v-model="course.course" :rules="[ v => !!v || 'Course name is required' ]" label="Name of Course" outlined></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field v-model="course.credits" :rules="[ v => !!v || v>0 || 'Course credits are required and cannot be zero' ]" label="Credits" type="number" min="0" max="4" outlined></v-text-field>
            </v-col>
            <v-col cols="11" sm="5" md="3">
              <v-text-field v-model="course.equivalent" label="Course Equivalent" outlined></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" color="error" @click="courses.splice(index, 1)" style="margin-top:1em">
                    <v-icon>fal fa-times</v-icon>
                  </v-btn>
                </template>
                <span>Remove Course</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-btn @click="courses.push({ course: '', credits: 0, equivalent: '' })">Add Course</v-btn>
          <v-divider style="margin: 2em"></v-divider>
          <p style="font-size:1.2em">
            Please read the <a href="https://docs.google.com/document/d/1WrGHMDynmEpz_CL5mr76Rt1MMDnKFw-GVA6R2uchwKw/edit?usp=sharing" target="_blank">Off-Campus Studies Agreement</a> and the
            <a href="https://docs.google.com/document/d/14k9A5zZPww7ET6-QGHVbnsthVQuLcnXV_vnMUUTZeZA/edit?usp=sharing" target="_blank">Off-Campus Standards of Conduct</a>.
          </p>
          <v-checkbox v-model="offCampusStudyAgreement" label="I have read and agree to the Off-Campus Studies Agreement" :rules="[ v => !!v || 'Required' ]"></v-checkbox>
          <v-checkbox v-model="standardsOfConductAgreement" label="I have read and agree to abide by the Off-Campus Standards of Conduct" :rules="[ v => !!v || 'Required' ]">"</v-checkbox>
          <p style="margin:2em 0 1em">A signature of approval from a parent or guardian will be required before your application is completed. This signature can be done on a smart phone or tablet, or on a computer with a mouse. Enter the email address of your parent/guardian and they will receive an email with instructions to complete the signature on their device.</p>
          <v-text-field v-model="guardianEmail" :rules="emailRules" label="Parent/Guardian Email" outlined></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions style="margin-top:2em">
        <v-btn color="primary" @click="submit">Submit</v-btn>
        <v-btn @click="saveAndReturn">{{ saveText }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn to="/student/study-abroad" exact>{{ closeText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<style>
.v-text-field.v-input--is-readonly > .v-input__control > .v-input__slot:before {
  border-width: 0
}
div.row.no-bottom-padding > div {
  padding-bottom: 0
}
</style>
<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const id = computed(() => root.$router.currentRoute.params.id)
    const name = ref('')
    const bannerId = ref('')
    const majors = ref([])
    const majorOptions = ref([])
    const gradYear = ref('')
    const gradYearOptions = ref([])
    const programOptions = ref([
      { city: 'Oxford, London', partner: 'Best Semester', international: true },
      { city: 'San Jose, Costa Rica', partner: 'Best Semester', international: true },
      { city: 'San Jose, Costa Rica', partner: 'ISA', international: true },
      { city: 'Amman, Jordan', partner: 'Best Semester', international: true },
      { city: 'Amman, Jordan', partner: 'CIEE', international: true },
      { city: 'Belfast, Northern Ireland', partner: 'Best Semester', international: true },
      { city: 'Kampala, Uganda', partner: 'Best Semester', international: true },
      { city: 'Brisbane, Australia', partner: 'Best Semester', international: true },
      { city: 'Los Angeles, California', partner: 'Best Semester', international: false },
      { city: 'Washington, D.C.', partner: 'Best Semester', international: false },
      { city: 'New York City, New York', partner: 'Kings College', international: false },
      { city: 'Tokyo, Japan', partner: 'EAI', international: true },
      { city: 'Seville, Spain', partner: 'Semester in Spain', international: true },
      { city: 'Granada, Spain', partner: 'CEA', international: true },
      { city: 'Strasbourg, France', partner: 'Acces', international: true },
      { city: 'Paris, France', partner: 'CEA', international: true },
      { city: 'Aix-en-Provence, France', partner: 'CEA', international: true },
      { city: 'Rome, Italy', partner: 'Rome with purpose', international: true },
      { city: 'Jerusalem, Israel', partner: 'Semester in Jerusalem', international: true },
      { city: 'Brussels, Belgium', partner: 'Semester in Brussels', international: true },
      { city: 'Valparaiso, Chile', partner: 'ISA', international: true },
      { city: 'Berlin, Germany', partner: 'ISA', international: true },
      { city: 'Heidelberg, Germany', partner: 'Heidelberg University', international: true }
    ])
    const program = ref('')
    const terms = computed(() => {
      let arr = []
      let dt = new Date()
      if (dt.getMonth() < 4) {
        // Allow for the fall and summer of the current year
        arr.push('Summer ' + dt.getFullYear())
        arr.push('Fall ' + dt.getFullYear())
      } else if (dt.getMonth() < 10) {
        // Allow for the spring of next year and following
        arr.push('Fall ' + dt.getFullYear())
        dt.setFullYear(dt.getFullYear() + 1)
        arr.push('Spring ' + dt.getFullYear())
        arr.push('Summer ' + dt.getFullYear())
        arr.push('Fall ' + dt.getFullYear())
      } else {
        // Allow for summer of next year and following
        dt.setFullYear(dt.getFullYear() + 1)
        arr.push('Summer ' + dt.getFullYear())
        arr.push('Fall ' + dt.getFullYear())
      }
      // Add one additional year's worth of term options
      dt.setFullYear(dt.getFullYear() + 1)
      arr.push('Spring ' + dt.getFullYear())
      arr.push('Summer ' + dt.getFullYear())
      arr.push('Fall ' + dt.getFullYear())
      return arr
    })
    const programTerm = ref('')
    const requiredForMajor = ref(false)
    const international = ref(false)
    const email = ref('')
    const emailRules = ref([
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ])
    const instagram = ref('')
    const commitment = ref('')
    const insuranceCompany = ref('')
    const emergencyContact = ref([{
      name: '',
      relation: '',
      phone: '',
      email: ''
    }])
    const pell = ref(null)
    const offCampusStudyAgreement = ref(null)
    const standardsOfConductAgreement = ref(null)
    const guardianEmail = ref('')
    const advisor = ref('')
    const advisorOptions = ref([
      { name: 'Moon, Jon', username: 'jon.moon' },
      { name: 'Baek, Jiewon', username: 'jiewon.baek' },
      { name: 'Bagby, Amy', username: 'bagby' },
      { name: 'Barham, Robert Erle', username: 'robert.barham' },
      { name: 'Beckman, Jack', username: 'jbeckman' },
      { name: 'Broussard, Phill', username: 'broussard' },
      { name: 'Corbett, Steve', username: 'corbett' },
      { name: 'Crossman, Brian', username: 'crossman' },
      { name: 'Cyr, Chris', username: 'chris.cyr' },
      { name: 'Davis, Bill', username: 'davis' },
      { name: 'Dennison, Bill', username: 'dennison' },
      { name: 'Dodson, Chris', username: 'dodson' },
      { name: 'Donaldson, Sarah', username: 'sarah.donaldson' },
      { name: 'Drexler, Jim', username: 'jdrexler' },
      { name: 'Dryden, Jeff', username: 'jeff.dryden' },
      { name: 'Eames, Kevin', username: 'eames' },
      { name: 'Fain, Lindsey', username: 'lindsey.fain' },
      { name: 'Finch, Scott', username: 'scott.finch' },
      { name: 'Follett, Richard', username: 'follett' },
      { name: 'Foreman, Cliff', username: 'foreman' },
      { name: 'Garris, Heath', username: 'heath.garris' },
      { name: 'Green, Jay', username: 'jdgreen' },
      { name: 'Haddad, Daphne', username: 'haddad' },
      { name: 'Hallstrom, Camille', username: 'hallstrom' },
      { name: 'Hecker, Nola', username: 'nola.stephens' },
      { name: 'Horne, Cale', username: 'cale.horne' },
      { name: 'Horton, Phil', username: 'phorton' },
      { name: 'Huffines, Sarah', username: 'shuffines' },
      { name: 'Humphries, Jeffrey', username: 'jeff.humphries' },
      { name: 'Hunt, John', username: 'hunt' },
      { name: 'Jackson, Alicia', username: 'ajackson' },
      { name: 'Jones, Scott', username: 'scjones' },
      { name: 'Joseph, Kayb', username: 'kayb.joseph' },
      { name: 'Kapic, Kelly', username: 'kapic' },
      { name: 'Kaufmann, Steve', username: 'kaufmann' },
      { name: 'Kim, Lok', username: 'lok.kim' },
      { name: 'Kreuze, Brandon', username: 'bkreuze' },
      { name: 'MacDougall, Dan', username: 'macdougall' },
      { name: 'Macallister, Gwen', username: 'macallister' },
      { name: 'Madueme, Hans', username: 'hans.madueme' },
      { name: 'Mask, Russell', username: 'rmask' },
      { name: 'Matos-Wood, Arwen', username: 'arwen.matoswood' },
      { name: 'Morris, Tim', username: 'morris' },
      { name: 'Morton, Jeff', username: 'jmorton' },
      { name: 'Morton, Paul', username: 'morton' },
      { name: 'Neiles, Tom', username: 'neiles' },
      { name: 'Nelson, Richard', username: 'nelson' },
      { name: 'Park, Heunggi', username: 'park' },
      { name: 'Pennington, Becky', username: 'pennington' },
      { name: 'Petcher, Don', username: 'petcher' },
      { name: 'Quatro, Scott', username: 'quatro' },
      { name: 'Ralston, Patricia', username: 'ralston' },
      { name: 'Robinson, Chris', username: 'chris.robinson' },
      { name: 'Rulon, Mike', username: 'mike.rulon' },
      { name: 'Rush, John', username: 'john.rush' },
      { name: 'Saiki, David', username: 'david.saiki' },
      { name: 'Sceggel, Tim', username: 'tim.sceggel' },
      { name: 'Shaw, Sandy', username: 'sanford.shaw' },
      { name: 'Slavovsky, Mark', username: 'mark.slavovsky' },
      { name: 'Stambaugh, Brianne', username: 'brianne.kobeck' },
      { name: 'Stern, Curt', username: 'curt.stern' },
      { name: 'Stewart, Ken', username: 'kstewart' },
      { name: 'Tahere, David', username: 'david.tahere' },
      { name: 'Tate, Bill', username: 'william.tate' },
      { name: 'Tinholt, Elaine', username: 'etinholt' },
      { name: 'Trimiew, Oliver', username: 'trimiew' },
      { name: 'Vos, Matt', username: 'vos' },
      { name: 'Ward, Herb', username: 'hward' },
      { name: 'Washburn, David', username: 'david.washburn' },
      { name: 'Weichbrodt, Elissa', username: 'elissa.weichbrodt' },
      { name: 'Wescher, Lance', username: 'wescher' },
      { name: 'Wingard, John', username: 'john.wingard' },
      { name: 'Wright, Phil', username: 'wright' },
      { name: 'Young, Bruce', username: 'bryoung' },
      { name: 'Yue, Carole', username: 'carole.yue' },
      { name: 'Zuidema, Dan', username: 'zuidema' }
    ])
    const courses = ref([{ course: '', credits: 0, equivalent: '' }])

    watch(id, () => {
      root.$feathers.service('student/study-abroad').get(id.value).catch((err) => {
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: err })
        root.$router.push('/student/study-abroad')
      }).then((data) => {
        let { pidm } = user.value
        if (pidm !== data.pidm) {
          root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'There was an error loading the requested application. Please select an active application from the list or start a new application.' })
          root.$router.push('/student/study-abroad')
        } else {
          name.value = data.name
          bannerId.value = data.bannerId
          if ('majors' in data && Array.isArray(data.majors) && data.majors.length > 0) majors.value = data.majors
          if ('gradYear' in data) gradYear.value = data.gradYear
          if ('program' in data) program.value = data.program
          if ('programTerm' in data) programTerm.value = data.programTerm
          if ('requiredForMajor' in data) requiredForMajor.value = data.requiredForMajor
          if ('international' in data) international.value = data.international
          if ('email' in data) email.value = data.email
          if ('instagram' in data) instagram.value = data.instagram
          if ('commitment' in data) commitment.value = data.commitment
          if ('insuranceCompany' in data) insuranceCompany.value = data.insuranceCompany
          if ('emergencyContact' in data && Array.isArray(data.emergencyContact) && data.emergencyContact.length > 0) emergencyContact.value = data.emergencyContact
          if ('pell' in data) pell.value = data.pell
          if ('offCampusStudyAgreement' in data) offCampusStudyAgreement.value = data.offCampusStudyAgreement
          if ('standardsOfConductAgreement' in data) standardsOfConductAgreement.value = data.standardsOfConductAgreement
          if ('guardianSignature' in data) guardianEmail.value = data.guardianSignature.email
          if ('courses' in data && Array.isArray(data.courses) && data.courses.length > 0) courses.value = data.courses
          if ('advisor' in data) {
            for (let i = 0; i < advisorOptions.value.length; i++) {
              if (advisorOptions.value[i].username === data.advisor) {
                advisor.value = advisorOptions.value[i]
                break
              }
            }
          }
        }
      })
    })

    onMounted(() => {
      root.$feathers.service('forms/select-options').find({ query: { name: 'Major Code' } }).then(({ data }) => {
        root.$feathers.service('forms/select-options').get(data[0].value).then(({ options }) => {
          majorOptions.value = options
        })
      })

      let dt = new Date()
      for (let i = 0; i <= 6; i++) {
        gradYearOptions.value.push(JSON.stringify(dt.getFullYear()))
        dt.setFullYear(dt.getFullYear() + 1)
      }

      for (let i = 0; i < programOptions.value.length; i++) {
        programOptions.value[i].search = programOptions.value[i].city + ' - ' + programOptions.value[i].partner
      }
    })

    function checkInternational (item) {
      if (typeof (item) === 'object' && 'international' in item) {
        international.value = item.international
      }
    }

    const form = ref(null)
    const formValid = ref(false)
    const validationActive = ref(false)

    function save (hideSnackbar) {
      let obj = {
        majors: majors.value,
        gradYear: gradYear.value,
        program: typeof (program.value) === 'object' ? program.value.search : program.value,
        programTerm: programTerm.value,
        requiredForMajor: requiredForMajor.value,
        international: international.value,
        email: email.value,
        instagram: instagram.value,
        commitment: commitment.value,
        insuranceCompany: insuranceCompany.value,
        emergencyContact: emergencyContact.value,
        pell: pell.value,
        courses: courses.value,
        advisor: advisor.value.username,
        guardianSignature: {
          email: guardianEmail.value.trim()
        }
      }
      if (offCampusStudyAgreement.value) {
        obj.offCampusStudyAgreement = new Date()
      }
      if (standardsOfConductAgreement.value) {
        obj.standardsOfConductAgreement = new Date()
      }
      if (advisor.value != null && typeof (advisor.value) === 'object') {
        obj['approvals.advisor.username'] = advisor.value.username
        obj['approvals.advisor.name'] = advisor.value.name
      }
      let snackbar = { active: true, color: 'success', timeout: 6000, text: 'Save Successful' }
      return root.$feathers.service('student/study-abroad').patch(id.value, obj).catch((err) => {
        snackbar.color = 'error'
        snackbar.text = err
      }).finally(() => {
        if (!hideSnackbar) root.$store.dispatch('main/snackbar', snackbar)
      })
    }

    function saveAndReturn () {
      save().then(() => {
        root.$router.push('/student/study-abroad')
      })
    }

    function submit () {
      validationActive.value = true
      save(true).then(() => {
        if (form.value.validate()) {
          let patch = {
            status: 'Pending',
            submitted: true,
            submittedDate: new Date(),
            approvals: [
              {
                department: 'Advisor',
                approved: false,
                name: advisor.value.name,
                username: advisor.value.username,
                messages: []
              },
              {
                department: 'Records',
                approved: false,
                messages: []
              },
              {
                department: 'Financial Aid',
                approved: false,
                messages: []
              },
              {
                department: 'Student Development',
                approved: false,
                messages: []
              },
              {
                department: 'Health Services',
                approved: false,
                messages: []
              },
              {
                department: 'PriestHill Counseling Services',
                approved: false,
                messages: []
              },
              {
                department: 'World Languages',
                approved: false,
                messages: []
              }
            ]
          }
          return root.$feathers.service('student/study-abroad').patch(id.value, patch).catch((e) => {
            let snackbar = { active: true, color: 'error', timeout: 6000, text: 'Error submitting: ' + e }
            root.$store.dispatch('main/snackbar', snackbar)
          }).then(async (data) => {
            let snackbar = { active: true, color: 'success', timeout: 6000, text: 'Submission Successful' }
            root.$store.dispatch('main/snackbar', snackbar)
            root.$router.push('/student/study-abroad')
          })
        }
      })
    }

    const saveText = computed(() => {
      const { name } = root.$vuetify.breakpoint
      if (name === 'xs' || name === 'sm') return 'Save'
      return 'Save and finish later'
    })
    const closeText = computed(() => {
      const { name } = root.$vuetify.breakpoint
      if (name === 'xs' || name === 'sm') return 'Close'
      return 'Close Application'
    })

    return {
      name,
      bannerId,
      majors,
      majorOptions,
      gradYear,
      gradYearOptions,
      programOptions,
      program,
      terms,
      programTerm,
      requiredForMajor,
      international,
      email,
      emailRules,
      instagram,
      commitment,
      insuranceCompany,
      emergencyContact,
      advisor,
      advisorOptions,
      courses,
      guardianEmail,
      pell,
      offCampusStudyAgreement,
      standardsOfConductAgreement,
      checkInternational,
      stringFormatDate,
      form,
      formValid,
      validationActive,
      save,
      saveAndReturn,
      submit,
      saveText,
      closeText
    }
  }
}
</script>
